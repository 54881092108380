export default [
  {
    width: 24,
    header: {
      type: 'text',
      caption: 'Address',
    },
    cell: {
      type: 'slot',
      name: 'address',
    },
  },
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'slot',
      name: 'currency',
    },
  },
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Token',
    },
    cell: {
      type: 'slot',
      name: 'token',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Balance',
    },
    cell: {
      type: 'slot',
      name: 'balance',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Aggregating limit',
    },
    cell: {
      type: 'slot',
      name: 'agreggatingLimit',
    },
  },
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Weight',
    },
    cell: {
      type: 'slot',
      name: 'weight',
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'slot',
      name: 'type',
    },
  },
];
