//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    id: { type: Number, default: 0 },
  },

  data() {
    return {
      input: {
        currency: '',
        weight: '',
        main: false,
        aggregating: false,
        agreggatingLimit: '',
      },
      address: '',
    };
  },

  computed: {
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        const {
          currency,
          address,
          weight,
          main,
          aggregating,
          agreggatingLimit,
        } = this.data;

        this.address = address;
        this.input.currency = currency;
        this.input.weight = weight;
        this.input.main = main;
        this.input.aggregating = aggregating;
        this.input.agreggatingLimit = agreggatingLimit;
      }
    },
  },

  methods: {
    ...mapActions('common', ['confirmAction']),

    clickHandler({ name, params }) {
      this.confirmAction({
        text: 'Are you sure?',
        callback: () => {
          this.$emit(name, params);
          this.$emit('input', false);
        },
      });
    },

    apply() {
      const { input, id } = this;
      this.$emit('apply', {
        ...input,
        id,
      });
    },

    remove() {
      const { id } = this;
      this.$emit('remove', { id });
    },
  },
};
