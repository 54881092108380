var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"aggregation"},[_c('div',{staticClass:"aggregation__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"aggregation__table"},[_c('UiTable',{attrs:{"data":_vm.preparedData,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px"},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"address",fn:function(ref){
var row = ref.row;
return [(row.isToken)?_c('v-icon',[_vm._v(" mdi-subdirectory-arrow-right ")]):_vm._e(),_vm._v(" "+_vm._s(row.address)+" ")]}},{key:"currency",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.currency)+" ")]}},{key:"token",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.token)+" ")]}},{key:"balance",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.balance || 0)+" "),_c('span',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(row.token || row.currency)+" ")])]}},{key:"agreggatingLimit",fn:function(ref){
var row = ref.row;
return [(!row.isToken)?[_vm._v(" "+_vm._s(row.agreggatingLimit)+" "),_c('span',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(row.currency)+" ")])]:_vm._e()]}},{key:"weight",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.weight)+" ")]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[(!row.isToken)?[(row.cold)?_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$t('Cold'))+" ")]):_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Hot'))+" ")]),(row.aggregating)?_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('Aggregating'))+" ")]):_vm._e()]:_vm._e()],2),_c('div',[(!row.isToken)?_c('v-icon',{on:{"click":function($event){return _vm.edit(row)}}},[_vm._v(" mdi-pencil ")]):_vm._e()],1)])]}}])})],1),_c('div',{staticClass:"mt-12"}),_c('Modal',{attrs:{"id":_vm.updatingWallet.id,"data":_vm.updatingWallet.input},on:{"apply":_vm.updateWallet,"remove":_vm.deleteWallet},model:{value:(_vm.updatingWallet.modal),callback:function ($$v) {_vm.$set(_vm.updatingWallet, "modal", $$v)},expression:"updatingWallet.modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }