//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import FinanceWalletsAggregation from '~/views/FinanceWallets/FinanceWalletsAggregation/FinanceWalletsAggregation.vue';

export default {
  components: {
    FinanceWalletsAggregation,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadFinanceWalletsAggregation(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('financeWalletsAggregation', {
      loadFinanceWalletsAggregation: 'loadData',
    }),
  },
};
