//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './FinanceWalletsAggregationFilters.vue';
import Modal from './FinanceWalletsAggregationModal.vue';

import tableConfig from './tableConfig';

export default {
  components: { Filters, Modal },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
      updatingWallet: {
        modal: false,
        input: {},
        id: 0,
      },
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('financeWalletsAggregation', ['page', 'pages', 'data', 'sort']),

    preparedData() {
      const { data } = this;
      const preparedData = [];
      data.forEach((e) => {
        preparedData.push(e);
        if (e.tokens && e.tokens.length) {
          e.tokens.forEach((t) => {
            preparedData.push({
              address: e.address,
              isToken: true,
              token: t.name,
              currency: e.currency,
              balance: t.balance,
            });
          });
        }
      });
      return preparedData;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('financeWalletsAggregation', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      updateWalletAction: 'updateWallet',
      deleteWalletAction: 'deleteWallet',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    edit(row) {
      const {
        id,
        currency,
        address,
        publicKey,
        enabled,
        weight,
        main,
        aggregating,
        agreggatingLimit,
      } = row;

      this.updatingWallet.id = id;
      this.updatingWallet.input = {
        currency,
        address,
        publicKey,
        enabled,
        weight,
        main,
        aggregating,
        agreggatingLimit,
      };
      this.updatingWallet.modal = true;
    },

    add() {},

    updateWallet(data) {
      this.confirmAction({
        title: 'Are you sure you want to update wallet?',
        callback: async () => {
          this.updatingWallet.modal = false;
          this.setGeneralProgress(true);
          try {
            await this.updateWalletAction(data);
            this.loader = true;
            await this.loadDataAction({});
            this.loader = false;
            this.setSuccessNotification('Wallet updated successfully');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },

    deleteWallet(data) {
      this.confirmAction({
        title: 'Are you sure you want to delete wallet?',
        callback: async () => {
          this.updatingWallet.modal = false;
          this.setGeneralProgress(true);
          try {
            await this.deleteWalletAction(data);
            this.loader = true;
            await this.loadDataAction({});
            this.loader = false;
            this.setSuccessNotification('Wallet deleted successfully');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },
  },
};
